<template>
  <div class="container" :style="height">
    <div class="title">同学展示</div>
    <div class="studentmain" v-if="!isShow">
      <dl class="flex-outside" v-for="(item,index) in itemlist" :key="index">
        <dt>
          <img :src="item.url" />
        </dt>
        <dd class="flex-inside">
          <h3 class="ellipsis-one">{{item.username}}</h3>
          <p>
            <span>ID:</span>
            <input v-model="item.userLong" :id="'copy_item'+index" readonly />
            <i class="el-icon-document-copy" @click="copyText(index)"></i>
          </p>
        </dd>
      </dl>
    </div>
    <!-- 暂无数据 -->
    <div class="none" v-if="isShow">
      <img src="../assets/images/none.png" alt />
    </div>
  </div>
</template>
<script>
// 查询课程可以推荐的人
import { selectRecommendUser } from "@/assets/js/api";
export default {
  name: "students",
  data() {
    return {
      height: { height: "" },
      imgBasUrl: this.imgBasUrl,
      id: "",
      itemlist: [],
      isShow: false
    };
  },
  methods: {
    // 可视区域高度
    getHight() {
      this.height.height = window.innerHeight - 180 + "px";
    },
    selectRecommendUser: function() {
      selectRecommendUser({
        couesrId: this.id
      }).then(result => {
        if (result.code == 0) {
          this.itemlist = JSON.parse(result.list);
          // console.log(this.itemlist)
        } else {
          this.isShow = true;
        }
      });
    },
    // 复制
    copyText: function(index) {
      let id = "copy_item" + index;
      var Url2 = document.getElementById(id);
      Url2.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message({
        message: "复制成功,可粘贴",
        type: "success"
      });
      this.$router.go(-1);
    }
  },
  created() {
    this.id = localStorage.getItem("courseId");
    this.getHight();
    window.addEventListener("resize", this.getHight);
  },
  destroyed() {
    window.removeEventListener("resize", this.getHight);
  },
  mounted() {
    this.selectRecommendUser();
  }
};
</script>
